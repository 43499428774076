<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" lg="8" xl="8">
        <main-page-carousel />
        <search-component />
        <hospital-carousel />
        <v-img
          width="100%"
          class="ai_banner"
          style="margin: 30px 0; border-radius: 40px; cursor: pointer"
          contain
          src="@/assets/images/main_page/aiBanner.png"
          @click="openSite()"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import HospitalCarousel from "../components/HospitalCarousel.vue";
import MainPageCarousel from "../components/MainPageCarousel.vue";
import SearchComponent from "../components/SearchComponent.vue";

export default {
  name: "MainPage",

  components: {
    MainPageCarousel,
    SearchComponent,
    HospitalCarousel,
  },
  created() {
    //console.log("hellow");
    //this.getTest();
  },
  computed: {
    ...mapState([]),
    ...mapGetters("test", ["GET_STRING"]),
  },
  methods: {
    ...mapMutations([]),

    ...mapActions("test", ["BRING_TEST"]),
    openSite() {
      window.open("https://thyroidus.com/");
    },
    /*async getTest() {
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: 10,
      };
      await this.BRING_TEST(searchOpt).then(() => {
        console.log(this.GET_STRING);
      });
    },*/
  },
};
</script>
