<template>
  <!-- <vueper-slides
    class="no-shadow pt-10 pb-10"
    :visible-slides="6"
    :autoplay="true"
    :duration="1000"
    :bullets="false"
    fixed-height="100px"
    :gap="1"
    :touchable="false"
    id="HospitalCarousel"
  >
    <vueper-slide
      style="
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      "
      v-for="(item, i) in listHospital"
      :key="i"
      :image="item.banner"
      :link="item.link"
    />
  </vueper-slides> -->
  <v-container fluid class="mb-10">
    <v-row justify="center" align="center" class="carouselBody" no-gutters>
      <v-col
        class="carouselItem"
        v-for="(item, index) in listHospital"
        :key="index"
        @click="openSite(item.link)"
      >
        <v-img
          class="carouselItemImg"
          :src="item.banner"
          contain
          :style="{ left: item.posX + 'px' }"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { VueperSlides, VueperSlide } from "vueperslides";
// import "vueperslides/dist/vueperslides.css";

export default {
  // components: { VueperSlides, VueperSlide },
  data() {
    return {
      cur_left: 0,
      width: 180,
      margin: 10,
      clength: 0,
      listHospital: [
        {
          banner: require(`@/assets/images/thanq-logo.svg`),
          link: "https://thanqseoul.modoo.at/",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/central.png`),
          link: "https://www.cseye.net/main/main.php",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/renew.png`),
          link: "http://www.rseye.co.kr/",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/oaro.png`),
          link: "https://oaroskin.com/ ",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/human.png`),
          link: "https://humic.co.kr/",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/jin.png`),
          link: "https://doctorjinent.modoo.at/",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/erum.png`),
          link: "http://eroomedi.com/",
          posX: 0,
        },
        {
          banner: require(`@/assets/images/fine.png`),
          link: "http://finehealthcare.ai/",
          posX: 0,
        },
      ],
    };
  },
  methods: {
    setLength() {
      this.clength = this.listHospital.length;
    },
    carouselActive() {
      let frame_delay = 300;
      let time_delay = 10;
      for (let i = 0; i < this.listHospital.length; i++) {
        this.listHospital[i].posX -= (this.width + this.margin) / frame_delay;
        if (this.listHospital[i].posX <= -(this.width + this.margin) * (i + 1))
          this.listHospital[i].posX +=
            (this.width + this.margin) * this.clength;
      }
      setTimeout(this.carouselActive, time_delay);
    },
    openSite(link) {
      window.open(link);
    },
  },
  created() {
    this.setLength();
    this.carouselActive();
  },
};
</script>
<style>
.carouselBody {
  text-align: center;
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
}
.carouselItem {
  display: inline-flex;
  max-width: 180px;
  min-width: 180px;
  margin-right: 10px;
  height: 110px;
  vertical-align: middle;
}
.carouselItemImg:hover {
  border: 1px solid lightgray;
  border-radius: 10px;
}
</style>
<style lang="scss" scoped>
#HospitalCarousel {
  margin-bottom: 150px;
}
</style>
