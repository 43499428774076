<template>
  <v-responsive :aspect-ratio="16 / 6">
    <v-carousel
      :cycle="true"
      interval="3000"
      style="width: 100%"
      :show-arrows="true"
      height="auto"
    >
      <v-carousel-item v-for="(item, i) in items" :key="i" fade>
        <v-img :src="item.src" eager></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-responsive>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("@/assets/images/slide_img/banner_1.png"),
        },
        {
          src: require("@/assets/images/slide_img/banner_2.png"),
        },
        {
          src: require("@/assets/images/slide_img/banner_3.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/MainPageCarousel.scss";
</style>
