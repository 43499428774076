<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-list two-line width="100%">
          <v-list-item-content class="main-search-title">
            <v-list-item-title
              class="reform_primary--text main-search-subtitle"
            >
              좋은 의원을 찾고 계신가요?
            </v-list-item-title>
            <v-list-item-title
              class="reform_primary--text main-search-subtitle"
            >
              가까운 지역순으로 결과를 보여드립니다.
            </v-list-item-title>
          </v-list-item-content>
        </v-list>

        <v-card class="main-search">
          <v-container fill-height fluid>
            <v-row align="center" justify="center" class="pt-5">
              <v-col
                cols="11"
                class="reform_primary--text main-search-content-text pb-0 pt-0"
              >
                <v-icon color="reform_primary"> mdi-magnify </v-icon>
                찾아보실 병원의 키워드 (병명, 주증상, 의사정보)를 입력해주세요.
              </v-col>
              <v-col cols="9" class="pt-0">
                <v-text-field
                  ref="searchField"
                  v-model="mainBanner.searchValue"
                  :placeholder="holder"
                  rounded
                  height="50px"
                  background-color="white"
                  @keyup.enter="searchHospital()"
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="pb-7">
                <v-btn
                  class="main-serch-btn"
                  elevation="0"
                  rounded
                  dark
                  color="reform_primary"
                  width="100%"
                  height="50px"
                  @click="searchHospital()"
                >
                  검색
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    ent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      holder: "",
      ranNum: this.ranNumFunc(),
      mainBanner: {
        ranList: {
          dName: ["소화기", "호흡기", "신장", "내분비", "면역", "혈액"],
          dSymptom: [
            "축농증수술",
            "시력저하",
            "어지럼증",
            "내시경",
            "알레르기",
            "흉터",
          ],
          dOperation: [
            "대장암",
            "폐렴",
            "신장이식",
            "갑상선",
            "동맥염",
            "골다공증",
          ],
          dEquipment: [
            "내시경",
            "Quark PFT(폐기능 검사기)",
            "투석",
            "CZT(골밀도 측정기)",
            "면역질환 진단키트",
            "혈액응고 검사",
          ],
        },
        searchValue: "",
      },
    };
  },
  mounted() {
    this.holder = this.mainBanner.ranList.dSymptom[this.ranNum];
  },
  methods: {
    ...mapMutations("basic", ["SET_SEARCH_PARAMS"]),
    ranNumFunc() {
      let max = 5;
      let min = 0;
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    searchHospital() {
      if (!this.ent) {
        if (this.mainBanner.searchValue === "") {
          this.mainBanner.searchValue =
            this.mainBanner.ranList.dSymptom[this.ranNum];
        }
        this.SET_SEARCH_PARAMS(this.mainBanner.searchValue);
        this.$router.push("/v/request/researchHospital").catch(() => {});
      } else {
        if (this.mainBanner.searchValue === "") {
          this.mainBanner.searchValue =
            this.mainBanner.ranList.dSymptom[this.ranNum];
        }
        this.$emit("ent_search", this.mainBanner.searchValue);
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/main.scss";
@import "../assets/scss/SearchComponent.scss";
</style>
